import React from 'react';
import { Helmet } from 'react-helmet';

import StaticPage from 'components/StaticPage';
import Layout from 'components/layout';

const TermsPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Terms of Service</title>
      <meta name="og:title" content="Terms of Service" />
      <meta name="twitter:title" content="Terms of Service" />
    </Helmet>
    <StaticPage id="360018739838" />
  </Layout>
);

export default TermsPage;
